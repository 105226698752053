import { render, staticRenderFns } from "./search-niv.vue?vue&type=template&id=2a664ebf&scoped=true&"
import script from "./search-niv.vue?vue&type=script&lang=js&"
export * from "./search-niv.vue?vue&type=script&lang=js&"
import style0 from "./search-niv.vue?vue&type=style&index=0&id=2a664ebf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a664ebf",
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QInput from 'quasar/src/components/input/QInput.js'
import QBtn from 'quasar/src/components/btn/QBtn.js'
qInstall(component, 'components', {QInput,QBtn})
