import { render, staticRenderFns } from "./card-step-mobile.vue?vue&type=template&id=41bc39c3&scoped=true&"
import script from "./card-step-mobile.vue?vue&type=script&lang=js&"
export * from "./card-step-mobile.vue?vue&type=script&lang=js&"
import style0 from "./card-step-mobile.vue?vue&type=style&index=0&id=41bc39c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bc39c3",
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QCarousel from 'quasar/src/components/carousel/QCarousel.js'
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js'
qInstall(component, 'components', {QCarousel,QCarouselSlide})
