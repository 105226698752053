//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchNiv from 'pages/autos/search-niv.vue'
import CardNiv from 'pages/autos/card-niv.vue'
import CardStep from 'pages/autos/card-step.vue'
import CardStepMobile from 'pages/autos/card-step-mobile.vue'
export default {
  name: 'service-suzuki',
  components: {
    SearchNiv,
    CardNiv,
    CardStep,
    CardStepMobile
  },
  data() {
    return {
      response: null,
      info: false
    }
  },
  methods: {
    dataNiv(r) {
      if (r.error) {
        this.info = false
        this.response = 'NIV no registrado'
      } else {
        this.info = true
        this.response = r
      }
    }
  }
}
