//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'search-niv',
  data() {
    return {
      numberNiv: null,
      responseNiv: {},
      sucess: null,
      emptyData: null
    }
  },
  methods: {
    onSubmit() {
      this.$axios
        .get(`niv/${this.numberNiv}`)
        .then(r => {
          this.responseNiv = r.data
          this.$emit('data', r.data)
          this.sucess = true
        })
        .catch(e => {
          this.sucess = false
          this.emptyData =
            'Actualmente no existe una campaña vinculada con tu modelo. Gracias por verificar.'
          let emptyData = { error: true }
          this.$emit('data', emptyData)
          console.log(e.message)
        })
        .finally(() => {})
    }
  }
}
