//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'card-step',
  data() {
    return {
      step: [
        {
          title: 'Localiza el NIV de tu vehículo',
          text:
            'Se encuentra en la tarjeta de circulación y en la placa metálica que está sobre el tablero del lado del conductor.'
        },
        {
          title: 'Introduce tu NIV en el campo',
          text:
            'El NIV puede tener entre 10 y 12 dígitos. El campo no admite letras o caracteres especiales.'
        },
        {
          title: 'Verifica las campañas de tu vehiculo',
          text:
            'Tu vehículo pude o no participar en una o más campañas. Si participa en alguna campaña acércate a tu asesor de confianza.'
        }
      ]
    }
  },
  methods: {}
}
