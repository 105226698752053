//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'card-niv',
  props: {
    response: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      numberNiv: null
    }
  },
  methods: {}
}
